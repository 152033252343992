/* body {
  margin: 0;
  font-family:"gothamBook" , -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "gothamBold";
  src: url("/public/fonts/Gotham-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamBook";
  src: url("/public/fonts/Gotham-Book.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamMedium";
  src: url("/public/fonts/Gotham-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none !important;
}
/* 
.ant-table-thead >tr > th > .ant-table-cell {
  text-align: center;
} */
.ant-table-thead > tr > th {
  text-align: center !important; 
}

.rpv-core__inner-pages{
  scrollbar-width: thin !important;
}