:where(.css-dev-only-do-not-override-mzwlov).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  margin-inline-end: -1px;
  padding-top: 0;
  padding-bottom: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  border-end-start-radius: 0;
  box-shadow: none;
  border-left: none !important;
}

/* client */
/* Reset any borders for the table */
.ant-table-bordered .ant-table-container {
  border: none;
}

/* Header cells have a bottom border */
.ant-table-thead > tr > th {
  background-color: red; /* Adjust this color to match your header background color */
  color: #595959; /* Adjust this color to match your header text color */
  border-bottom: 0px !important;
  border-top: 1px solid #94a2f2;
}

/* All body cells have a bottom border */
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #94a2f2; /* Adjust this color to match the image */
}

/* The first and last cells in each row have left and right borders respectively */
.ant-table-tbody > tr > td:first-child,
.ant-table-thead > tr > th:first-child {
  border-left: 1px solid #94a2f2; /* Adjust this color to match the image */
}

.ant-table-tbody > tr > td:last-child,
.ant-table-thead > tr > th:last-child {
  border-right: 1px solid #94a2f2; /* Adjust this color to match the image */
}

/* Top border for the first row of body cells */
.ant-table-tbody > tr:first-child > td {
  border-top: 1px solid #94a2f2; /* Adjust this color to match the image */
}
.ant-table-tbody {
  border-top: 1px solid #94a2f2; /* Adjust this color to match the image */
}

/* Remove the last bottom border if you have a footer or pagination */
.ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid #94a2f2;
  /* border-start-start-radius: 5px; */

  border-end-start-radius: 5px;
}

/* If you want to add a border around the whole table, uncomment this */
/* .ant-table-bordered {
  border: 1px solid #1890ff;
} */

/* Hover state for rows - if you want to maintain the hover effect */
.ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff; /* Adjust this color to match the image */
}
/* :where(.css-mzwlov).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color:  black  !important ;
} */
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  transition: background 0.2s, border-color 0.2s;
  /* border-bottom: 1px solid #94a2f2; */
}

.brief-review-tabs .ant-tabs-card .ant-tabs-card-bar {
  border-color: #e8e8e8;
}

.brief-review-tabs .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.brief-review-tabs .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #e8e8e8;
}

/* Additional custom styles here to match your design exactly */
/* Override Ant Design Table Pagination styles */
.ant-table-pagination {
  display: flex;
  justify-content: space-between; /* This will align the page info to the left and icons to the right */
}

/* Make sure the total entries text is on the left side */
.ant-table-pagination .ant-pagination-total-text {
  margin-right: auto; /* This pushes everything else to the right */
}

/* Align the navigation icons to the right */
.ant-table-pagination .ant-pagination {
  margin-left: auto; /* This pushes the navigation icons to the right */
}

:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  /* outline: none; */
  /* transition: all 0.2s; */
}

:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-item-active
  a {
  color: white;
  background: #294799;
  border-radius: 5px;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  background: white;
  border: 1px solid #294799 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: blue;
  border-color: blue;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.ant-steps-item-title {
  color: #94a2f2; /* This will set the title color */
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #94a2f2;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #94a2f2;
}

.ant-steps-item-finish .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65); /* Adjust color for finished steps if needed */
}

/* Add custom CSS to match your design */

/* Remove the connector line */
.ant-steps-item-tail {
  display: none;
}

/* Style the step number box */
.ant-steps-item-icon {
  border-radius: 0; /* Makes the icon box-shaped */
  width: 25px; /* Width of the box */
  height: 25px; /* Height of the box */
}

/* Adjust the position to center the number in the box */
.ant-steps-item-icon > .ant-steps-icon {
  line-height: 25px; /* Centers the number vertically */
  margin-top: 0;
}

/* Change background and border color for the active step */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #94a2f2;
  border-color: #94a2f2;
}

/* Set the color of the active step number */
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

/* Adjust the finished and pending step styles accordingly */
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent; /* Set a background color if needed */
  border-color: #d9d9d9;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: #d9d9d9;
}

/* Adjust the title style for all steps */
.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65); /* Color for non-active titles */
}

/* Style the title of the active step */
.ant-steps-item-process .ant-steps-item-title {
  color: #94a2f2; /* Color for the active title */
}

/* Adjust padding for the step content */

:where(.css-dev-only-do-not-override-mzwlov).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #94a2f2;
  border-color: #94a2f2;
  border-radius: 10px !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon {
  background-color: rgba(0, 0, 0, 0.06);
  border-color: transparent;
  border-radius: 10px !important;
}

/* Custom styles for the Ant Design Steps component */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #94a2f2; /* Blue background for the current step */
  color: white; /* White text */
  border-radius: 0; /* Square shape */
}

/* Style for the number inside the box for the current step */

/* Hide the default icon for non-current steps */

/* Adjust the position and size of the arrow icon */
.ant-steps-item-title img {
  margin-left: 8px; /* Space between title and arrow icon */
  height: 12px; /* Match the arrow icon size to your design */
  vertical-align: middle; /* Align arrow icon vertically */
}

/* Style adjustments for the current step */
.centered-button {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.custom-continue-button {
  background-color: #94a2f2; /* Your desired background color */
  font-family: gothamBook;
  border-radius: 10px; /* Rounded corners */
  color: white; /* Text color */
  font-size: 16px; /* Text size */

  /* Style for the hover state */
}

/* in your CSS file */
.custom-search {
  width: 250px;
  height: 40px;
  border-right: none;
  margin-right: 10px;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
  border-left: none !important;
}

.anticon svg {
  display: inline-block;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  color: #3e3e3e;
  font-weight: 500;
}

.ant-pagination {
  text-align: center !important;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1;

  flex-direction: row-reverse;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-form-item
  .ant-form-item-label
  > label {
  font-family: gothamMedium;
  font-weight: 500;
  flex-direction: row-reverse;
  margin: 0;
  gap: 4px !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-form-vertical
  .ant-form-item-label
  > label::after {
  display: none !important;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: #c6cbfd !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: #c6cbfd !important;
}
.custom-button-tabs
  :where(.css-dev-only-do-not-override-mzwlov).ant-steps
  .ant-steps-item-title::after {
  display: none !important;
}

.custom-button-tabs .anticon svg {
  display: inline-block;
  color: #292d32;
  margin-top: 7px;
}
.ant-steps-item-process .ant-steps-item-title {
  font-family: gothamBook !important;
  font-weight: 600 !important;
  font-size: 16px;
  color: #3e3e3e !important ;
}
.profile-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.profile-container .ant-avatar {
  margin-right: 20px;
}

.profile-container .ant-typography {
  margin-bottom: 10px;
}

.profile-container .ant-divider {
  margin: 16px 0;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: #f3f3ff !important;
}
/* 
.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #294799 !important;
} */
:where(.custom-tabs).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #294799 !important;
}
:where(.custom-tabs).ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #aaaaaa !important;
}
.ant-tabs-ink-bar {
  background-color: #294799 !important;
}

.tab-count {
  background-color: #294799;
  color: white;
  border-radius: 12px;
  padding: 0 9px;
  margin-left: 4px;
  font-size: 12px;
  display: inline-block;
  line-height: 1.5;
}

/* .custom-tabs .ant-tabs-tab:hover .tab-count {
  background-color: #294799;
  color: white;
}

.custom-tabs .ant-tabs-tab-active .tab-count {
  background-color: #294799;
  color: white;
}
.custom-tabs .ant-tabs-tab {
  font-family: gothamMedium;
  font-weight: 500;
  font-size: 16px;
  color: #aaaaaa !important;
} */

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  transition: background 0.2s, border-color 0.2s;
  /* border-bottom: 1px solid #94a2f2 !important; */
  font-family: gothamBook !important ;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: #f3f3ff !important;
  font-family: gothamMedium !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #94a2f2 !important;
  text-wrap: wrap;
}
/* ext-wrap: nowrap; */
/* .my-plan-view-table .ant-table-tbody > tr > td {
  text-wrap: nowrap !important; 
} */

:where(.css-mzwlov).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-mzwlov).ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500 !important;
  text-align: start;
  background: #f3f3ff !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  background: white;
  border: 1px solid #294799 !important;
}
:where(.css-mzwlov).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-mzwlov).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  background: white;
  /* border: 1px solid #294799 !important; */
  border: 1.5px solid #94a2f2 !important ;
}

.css-pmi5bg.Mui-selected {
  background-color: #f3f2ff !important;
}

.ant-select-arrow {
  color: rgba(41, 71, 153, 1) !important ;
  stroke: #294799;
  stroke-width: 30px;
}
.ant-select-selection-item {
  font-family: gothamBook;
  font-size: 13px;
  font-weight: 400px;
}

/* For Tables */
.ant-table-thead {
  font-family: gothamMedium;
  font-size: 16px;
}

.ant-table-tbody > tr > td {
  border-color: #94a2f2 !important;
}
.ant-table-thead > tr > th {
  border-color: #94a2f2 !important;
}

/* .ant-table-thead >tr > th > .ant-table-cell {
  text-align: center;
} */
.ant-table-thead > tr > th {
  text-align: center !important;
}
/* .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px;
} */

.ant-table-tbody > tr > td {
  border-bottom-left-radius: 0px !important;
}

.ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px !important;
}
.ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 0px !important;
}

.anticon-left,
.anticon-right {
  color: #94a2f2 !important ;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  background: white;
  /* border: 1px solid #294799 !important; */
  border: 1.5px solid #94a2f2 !important ;
}

:where(.css-mzwlov).ant-pagination .ant-pagination-item-active a {
  color: white !important;
  background: #294799 !important;
  border-radius: 5px !important;
}

:where(.css-mzwlov).ant-table-wrapper .ant-table > tbody {
  font-family: gothamBook !important ;
}

/* For tabs */
/* 
.my-custom-tabs.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #aaa !important ;
}
  */
/* :where(.css-dev-only-do-not-override-mzwlov).ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #294799 !important ;
} */
/* :where(.css-dev-only-do-not-override-mzwlov).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), */

:where(.css-mzwlov).ant-table-wrapper .ant-table {
  font-family: gothamBook !important ;
}
.site-layout-background {
  padding: 0px 10px;
  width: 100% !important;
}
.Brief-Review-Form-Heading {
  font-family: gothamMedium !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #3e3e3e !important;
}
.Brief-Review-Form-SubHeading {
  font-family: gothamMedium !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #3e3e3e !important;
}

/* For required fields in forms */

.client-form-container {
  font-family: gothamMedium !important;
  font-weight: 600;
}

:where(.css-mzwlov).ant-form-item .ant-form-item-label > label {
  font-family: gothamMedium !important;
  font-weight: 500;
  flex-direction: row-reverse;
  margin: 0;
  gap: 2px;
}

:where(.css-mzwlov).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  margin-inline-end: 2px;
  color: #ff4d4f !important ;
  font-size: 14px;
  font-family: SimSun, sans-serif !important;
  line-height: 1;
  /* display: none !important; */
}

:where(.css-mzwlov).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: none !important;

  /* display: none !important; */
}
/* 
:where(.css-mzwlov).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f !important ;
  font-size: 14px;
  font-family:  gothamBook ;
  line-height: 1;
  display: none !important;
}

:where(.css-mzwlov).ant-form-item .ant-form-item-label >label::after {
  content:  * ;
  position: relative;
 
  color: red;
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f !important ;
  font-size: 14px;
  font-family:  gothamBook ;
  line-height: 1;
  display: none !important;
} */

:where(.css-mzwlov).ant-pagination .ant-pagination-item-active {
  border: none !important ;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-pagination
  .ant-pagination-item-active {
  border: none !important ;
}

/* For adjusting pagination on bottom of page */
.ant-spin-container {
  display: flex;
  /* border: 1px solid red; */
  flex-direction: column;
  justify-content: space-between;
  /* min-height: calc(70vh - 100px); */
}

/* Remove site icon */
/* .anticon-delete {
  margin-bottom:  10%;
} */
.remove-icon {
  margin-bottom: 10% !important;
}

/* For add sites buttons */
:where(.css-dev-only-do-not-override-mzwlov).ant-btn-primary {
  background: rgb(41, 71, 153);
}

:where(.css-mzwlov).ant-btn-primary {
  background: rgb(41, 71, 153);
}

/* .Custom-Create-Button {
  border-color: #294799 !important;
  background-color: transparent !important; 
}
*/
.Custom-Create-Button:hover {
  color: black !important;
}

.custom-search .ant-input-group-addon {
  border: none;
}
.custom-search {
  width: 100%; /* Ensure the input takes full width */
}

.custom-search .ant-input-affix-wrapper {
  border-radius: 4px;
}

.custom-search .ant-input {
  padding-left: 7px; /* Adjust to ensure proper spacing */
}
:where(.css-dev-only-do-not-override-mzwlov).ant-btn-primary :hover {
  background: #294799 !important;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background: #294799 !important;
}
:where(.css-mzwlov).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background: #294799 !important;
}


/*
:where(.css-mzwlov).ant-table-wrapper .ant-table {
  margin-left: 0.6%;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table {
  margin-left: 0.6%;
}
*/
:where(.css-dev-only-do-not-override-mzwlov).ant-tag {
  margin-inline-end: 0px !important;
}

:where(.css-mzwlov).ant-tag {
  margin-inline-end: 0px !important;
}

/* Plan status tag stylings */
.status-accepted {
  background-color: #e5ffe0;
  border-color: #1eb400;
  color: #1eb400;
}

.status-pending {
  background-color: #fff8ee;
  border-color: #e8b100;
  color: #e8b100;
}

.status-inprogress {
  background-color: #f2e9ff;
  border-color: #4600a8;
  color: #4600a8;
}
.status-In-progress {
  background-color: #f2e9ff;
  border-color: #4600a8;
  color: #4600a8;
}
.status-lostplan {
  background-color: #ffeaea;
  border-color: #ff0004;
  color: #ff0004;
}

.status-rejected {
  background-color: #ffeaea;
  border-color: #ff0004;
  color: #ff0004;
}

.status-modification-requests {
  background-color: #e9f0ff;
  border-color: #13357c;
  color: #13357c;
}
.status-modification-inprogress {
  background-color: #e9fffb;
  border-color: #00be9e;
  color: #00be9e;
}

/* finalize-nohover */

.finalize-nohover :hover {
  /* border: 1.5px solid #294799 !important; */
  color: #294799 !important ;
  font-family: gothamBook;
  font-weight: 600;
  background: #ffffff !important;
}

:where(.finalize-nohover).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  /* border:  1.5px solid #294799  !important; */
  color: #294799 !important ;
  font-family: gothamBook;
  font-weight: 600;
  background: #ffffff !important;
}

.finalize-nohover
  :where(.css-dev-only-do-not-override-mzwlov).ant-btn-primary:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  background: #ffffff !important;
  color: #294799 !important ;
}

:where(.pre-campaign-form).ant-input-outlined.ant-input-disabled,
:where(.pre-campaign-form).ant-input-outlined[disabled] {
  color: black;
  background-color: #f2f3ff !important;
  border-color: #d9d9d9 !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.pre-campaign-form
  :where(
    .css-dev-only-do-not-override-mzwlov
  ).ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined[disabled] {
  color: black;
  background-color: #f2f3ff !important;
  border-color: #d9d9d9 !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

:where(.css-mzwlov).ant-input-outlined.ant-input-disabled,
:where(.css-mzwlov).ant-input-outlined[disabled] {
  color: black;
  background-color: #f2f3ff !important;
  border-color: #c8c4c4 !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

/* For disabled button */
:where(.css-dev-only-do-not-override-mzwlov).ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

/* For disabled button */
:where(.css-mzwlov).ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

/* Image preview */
:where(.css-dev-only-do-not-override-mzwlov).ant-image-preview-root
  .ant-image-preview-img {
  height: 400px !important ;
}

/* Drawer */
.MuiDrawer-root .MuiDrawer-docked .css-1f2xuhi-MuiDrawer-docked {
  z-index: 1 !important;
}

.css-1f2xuhi-MuiDrawer-docked {
  z-index: 1 !important;
}
.css-raoxoc-MuiDrawer-docked {
  z-index: 1 !important;
}

/* Table scrollbar styling */

.ant-table-body {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #94a2f2 #f1f1f1; /* Color of thumb and track */
  border-radius: 4px !important;
}

/* drawer */
.MuiDrawer-root {
  z-index: 1 !important;
}

/* Page selector */
.ant-select-item.ant-select-item-option-selected {
  background-color: rgba(25, 118, 210, 0.08) !important;
  /* color: #3e3e3e !important; */
}

/* Brief container */
.brief-container {
  padding-left: 10px;
  padding-right: 10px;
}

.summary-table .ant-table-thead > tr > th,
.summary-table-medium .ant-table-thead > tr > th {
  background-color: #94a2f2 !important;
  border: none !important;
}

/* Removing client management table extra border */
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-top: none !important;
  border-inline-start: none !important ;
}

/* Reducing ant tabs spacing(margin-right) */
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0px 0px 0px 15px !important ;
}

/* .my-plan-view-table .ant-table-thead>tr>th {
 
  white-space: nowrap !important;         
  overflow: hidden !important;             
  text-overflow: ellipsis !important;     
  max-width: 130px !important;    
  text-align: left !important;         
} */

.ant-tabs-tab-active {
  color: white !important ;
}

/* .custom-plan-tabs .ant-tabs-nav{
  margin-top : 2% ;
} */

/* KVA */
.kva-container {
  margin-left: 15px;
}
.kva-heading {
  font-family: gothamMedium;
  font-size: 20px;
  font-weight: 500;
  color: #294799;
}
.kva-links-container {
  border-radius: 7px;
  width: 800px;
  /* padding : 2px ; */
  overflow-y: auto;
  border: 1px solid #aaaaaa;
  height: 200px;
  padding: 12px;
}
.kva-links-text {
  list-style-type: disc;
  padding-left: 20px;
  font-family: gothamBook;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0;
}

/* For requested site inputs */
.custom-requested-site-input {
  color: #3e3e3e !important;
  font-family: gothamBook;
  font-weight: 400;
  font-size: 16px;
  height: 41px;
  margin-top: 12px;
}
.custom-add-requested-site-input {
  color: #3e3e3e !important;
  font-family: gothamBook;
  font-weight: 400;
  font-size: 16px;
  height: 41px;
}

.blue-btn {
  font-family: gothamBook;
  font-weight: 500;
  font-size: 16.6px;
  color: white;
  background-color: #294799;
  border-radius: 8.3px;
  width: 125px;
  height: 41px;
}
.secondary-btn {
  font-family: gothamBook;
  font-weight: 500;
  font-size: 16.6px;
  color: #294799;
  background-color: white;
  border: 1px solid #294799;
  border-radius: 8.3px;
  width: 125px;
  height: 41px;
}

.secondary-btn:hover {
  color: #294799 !important ;
  background-color: transparent;
  border: 1px solid #294799 !important;
  border-radius: 8.3px;
}

/* media query for main content*/
.main-content {
  width: 96%;
}

@media (max-width: 1440px) {
  .main-content {
    width: 96%;
  }
}

@media (min-width: 1500px) {
  .main-content {
    width: 98%;
  }
}

.MuiDrawer-root {
  transition: width 0.3s ease-in-out;
}

.sidebar-icon {
  transition: all 0.3s ease-in-out;
}

.MuiDrawer-root {
  position: fixed !important;
  /* z-index: 1100 !important; */
}

.MuiDrawer-paper {
  position: fixed !important;
}

.main-content {
  position: relative;
  z-index: 1;
}

/* For removing space before form items labels not required */

:where(.css-mzwlov).ant-form-item .ant-form-item-label > label::after {
  display: none;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-form-item
  .ant-form-item-label
  > label::after {
  display: none;
}

/* for client profile tabs */
.profile-tab-buttons.active-tab {
  background-color: #294799; /* Change to the active tab color */
  color: white; /* Text color for active tab */
  pointer-events: none; /* Disable clicks on the active tab */
}


.ant-modal .ant-modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}